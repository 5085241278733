import React, { useMemo, useState } from 'react'
import useGetFarmLpPools from 'state/data/hooks/useGetFarmLpPools'
import { useGetTokensPrice } from 'state/data/hooks/useTokenPrice'
import useGetUserFarmLpPools from 'state/data/hooks/useGetUserFarmPools'
import styled, { createGlobalStyle } from 'styled-components'
import { AutoColumn } from 'components/Column'
import { TYPE } from 'theme'
import Swtich from 'components/Swtich'
import Select from 'components/Select'
import { Input, InputAdornment } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import Numeral from 'numeral'
import useGetOverviewData from 'state/data/hooks/useGetOverviewData'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from 'components/Swtich'
import PoolItem from './components/PoolItem'
import SettingDark from 'assets/images/farm/setting-dark.png'
import SettingWhite from 'assets/images/farm/setting-white.png'
import FarmMigrateGuideModal from 'components/FarmMigrateGuideModal'
import useGetBoostedFarmPools from 'state/BoostedFarm/hooks/useGetBoostFarmPools'
import useGetUserBoostedFarmPools from 'state/BoostedFarm/hooks/useGetUserBoostedFarmPools'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import storage from 'utils/storage'
const ThemedGlobalStyle = createGlobalStyle`
    #farm-setting {
        .MuiPaper-root {
            background-color: ${({ theme }) => theme.v2.bg02};

        }
        .MuiList-root  {
            padding: 0 !important;
        }
    }
`

const Wrapper = styled.div`
  width: 940px;
  margin: 0 auto;
  /* margin-top: 30px; */

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
  `}
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FarmIcon = styled.img`
  width: 129px;
  height: 102px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 70px;
    height: 60px;
  `}
`

const SwitchTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.v2.bg06};
  width: 240px;

  height: 30px;
  border-radius: 8px;
  margin-left: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 0;
    width: 190px;
  `}
`

const SwitchTab = styled.div`
  width: 80px;
  border-radius: 8px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 14px;
  height: 100%;
  cursor: pointer;

  font-weight: 500;
  &.active {
    background-color: #d2cfd5;
    color: #000;
  }
`

const ToolsWrapper = styled.div`
  background: ${({ theme }) => theme.v2.bg02};
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: 12px;
  width: 100%;
  margin-top: 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    padding: 8px;
  `}
`

const LeftTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .iconfont {
    font-size: 20px;
    color: ${({ theme }) => theme.v2.t02};
    cursor: pointer;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}

    &:first-child {
      margin-right: 8px;
    }

    &:hover,
    &.active {
      color: ${({ theme }) => theme.v2.t01};
    }
  }

  .icon-list {
    font-size: 21px;
    padding-top: 4px;
  }
`

const MyLiquidityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const SortByWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 10px;
  `}
`

const Search = styled(Input)`
  &.MuiInputBase-root {
    background: ${({ theme }) => theme.v2.bg04};

    height: 32px;

    border-radius: 24px;
    padding: 0 12px;

    &::after,
    &::before {
      border: none !important;
    }
    input {
      color: ${({ theme }) => theme.v2.t01};

      font-size: 12px;
      ::placeholder {
        color: ${({ theme }) => theme.v2.t02};
      }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 150px;
    margin-top: 10px;
  `}
  }
`
const CustomSearchIcon = styled(SearchOutlined)`
  color: ${({ theme }) => theme.v2.t02};
`

const PoolsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  > div {
    width: 460px;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 20px;
    margin-bottom: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
   > div {
    width: 100%;
    }
  `}
`

const StyledSettingIcon = styled.div`
  width: 21px;
  height: 19px;
  background-image: ${({ theme }) => (theme.isDark ? `url(${SettingWhite})` : `url(${SettingDark})`)};
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 10px;
  `}
`

const StyledMenu = styled(Menu)``

const StyledMenuItem = styled(MenuItem)`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.v2.bg02};
  color: ${({ theme }) => theme.v2.t01} !important;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MintInfoButton = styled.div`
  padding: 8px;
  background: rgba(88, 219, 211, 0.1);
  border-radius: 8px;

  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t03};
  cursor: pointer;
`

const Warning = styled(ErrorOutlineIcon)`
  width: 16px !important;
  height: 16px !important;
  margin-right: 8px;
  transform: rotate(180deg);
  color: ${({ theme }) => theme.v2.t03};
`

const SortItems = [
  {
    text: 'APR',
    value: 'apr',
  },
  {
    text: 'TVL',
    value: 'tvl',
  },
  {
    text: 'Rewards',
    value: 'rewards',
  },
]

const storageKey = 'farm-boost-guide'

export default function Farm() {
  const [isMigrateModalOpen, setMigrateModalOpen] = useState(!storage.get(storageKey))

  function onMigrateModalOpen() {
    storage.set(storageKey, true)
    setMigrateModalOpen(false)
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { overview } = useGetOverviewData()
  const [filterTab, setFilterTab] = useState('all')

  const [search, setSearch] = useState('')
  const [myLiquidityChecked, setMyLiquidityChecked] = useState(false)
  const [sort, setSort] = useState('apr')
  const [showDetailApr, setShowDetailApr] = useState(false)

  const userPoolIds = useGetUserFarmLpPools()

  const userBoostedPoolIds = useGetUserBoostedFarmPools()

  const lpPools = useGetFarmLpPools()
  const boostedPools = useGetBoostedFarmPools()

  const rewardTokensAddress = useMemo(() => {
    let res = lpPools
      ? lpPools.filter((item) => !!item.rewarder).map((item) => item.rewarder?.rewardToken.address.toLowerCase() || '')
      : []

    res = res.concat(
      boostedPools
        .filter((item) => !!item.rewarder)
        .map((item) => item.rewarder?.rewardToken.address.toLowerCase() || '')
    )
    return Array.from(new Set(res))
  }, [lpPools, boostedPools])

  // update fetchToken state to fetch reward token price
  useGetTokensPrice(rewardTokensAddress)

  const allComputedPool = useMemo(() => {
    let res = [...lpPools, ...boostedPools]
    if (filterTab === 'boost') return [...boostedPools]

    if (filterTab === 'end') {
      res = res.filter((item) => !Number(item.allocPoint) && !Number(item?.rewarder?.tokenPerSec || 0))
    } else {
      res = res.filter((item) => !!Number(item.allocPoint) || Number(item?.rewarder?.tokenPerSec || 0) > 0)
    }

    if (myLiquidityChecked) {
      res = res.filter(
        (item) =>
          (!item.isBoosted && userPoolIds.indexOf(item.id) >= 0) ||
          (item.isBoosted && userBoostedPoolIds.indexOf(item.id) >= 0)
      )
    }

    if (!search) {
      res = [...res]
    } else {
      res = [
        ...res.filter((item) => {
          const token0Symbol = item.pair.token0.symbol.toLowerCase()
          const token1Symbol = item.pair.token1.symbol.toLowerCase()
          const searchValueReg = new RegExp(search.toLowerCase())

          return searchValueReg.test(token0Symbol) || searchValueReg.test(token1Symbol)
        }),
      ]
    }

    if (res.length) {
      res = res.sort((a, b) => {
        if (sort === 'tvl') {
          return Number(b.totalDepositVolumeUSD) - Number(a.totalDepositVolumeUSD)
        } else if (sort === 'rewards') {
          return Number(b.nettPerSec) - Number(a.nettPerSec)
        } else {
          // apr
          const aApy = a.rewarderApy + a.lpApy + a.nettApy
          const bApy = b.rewarderApy + b.lpApy + b.nettApy
          return bApy - aApy
        }
      })
    }
    return res
  }, [lpPools, boostedPools, filterTab, sort, myLiquidityChecked, search, userPoolIds, userBoostedPoolIds])

  return (
    <Wrapper>
      <ThemedGlobalStyle />
      <TitleWrapper>
        <FarmIcon src={require('assets/images/farm/banner.png')} />
        <AutoColumn justify="center" gap="md">
          <TYPE.v2Main fontWeight="bold" fontSize={32}>
            Farms
          </TYPE.v2Main>
          <TYPE.desc fontSize={14} textAlign="center">
            Staking your LP tokens to harvest more NETT tokens
          </TYPE.desc>
          <TYPE.v2Main fontWeight="bold" fontSize={20} textAlign="center">
            {Numeral(overview?.farmTvl || 0).format('$ 0,0')} Total Value Locked (TVL)
          </TYPE.v2Main>
          <MintInfoButton
            onClick={() => {
              setMigrateModalOpen(true)
            }}
          >
            <Warning />
            Hint for Boost farms!
          </MintInfoButton>
        </AutoColumn>
      </TitleWrapper>

      <ToolsWrapper>
        <LeftTools>
          <SwitchTabWrapper>
            <SwitchTab className={filterTab === 'all' ? 'active' : ''} onClick={() => setFilterTab('all')}>
              Active
            </SwitchTab>
            <SwitchTab className={filterTab === 'boost' ? 'active' : ''} onClick={() => setFilterTab('boost')}>
              Boost
            </SwitchTab>
            <SwitchTab className={filterTab === 'end' ? 'active' : ''} onClick={() => setFilterTab('end')}>
              Ended
            </SwitchTab>
          </SwitchTabWrapper>
        </LeftTools>
        <MyLiquidityWrapper>
          <TYPE.v2Main fontSize={14} marginRight="12px">
            My farms
          </TYPE.v2Main>
          <Swtich checked={myLiquidityChecked} onChange={setMyLiquidityChecked} />
        </MyLiquidityWrapper>
        <SortByWrapper>
          <TYPE.v2Main fontSize={14} marginRight="12px">
            SORT BY
          </TYPE.v2Main>
          <Select value={sort} items={SortItems} onChange={setSort} keyName="farm-sort" />
        </SortByWrapper>
        <StyledSettingIcon
          onClick={(e) => {
            setAnchorEl((e as any).currentTarget)
          }}
        />
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id="farm-setting"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <StyledMenuItem>
            Show Full APR Details &nbsp;&nbsp; <Switch onChange={setShowDetailApr} />
          </StyledMenuItem>
        </StyledMenu>
        <Search
          type="text"
          id="search-by-symbol"
          placeholder="Search farm by symbol "
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value)
          }}
          endAdornment={
            <InputAdornment position="start">
              <CustomSearchIcon />
            </InputAdornment>
          }
        />
      </ToolsWrapper>
      <PoolsWrapper>
        {allComputedPool.map((item) => (
          <PoolItem key={`${item.isBoosted ? 'b' : 'f'}-${item.id}`} pool={item} showDetailApr={showDetailApr} />
        ))}

        {!allComputedPool.length && (
          <div style={{ textAlign: 'center', display: 'block', margin: '20px auto' }}>
            <div className="empty">
              <img width="60px" src={require('assets/images/launchpad/empty.png')} alt="Empty" />
              <TYPE.desc>Empty</TYPE.desc>
            </div>
          </div>
        )}
      </PoolsWrapper>
      <FarmMigrateGuideModal onClose={onMigrateModalOpen} isOpen={isMigrateModalOpen} />
    </Wrapper>
  )
}
