/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js'
import { AuthButton } from 'components/Button'
import Row, { RowBetween } from 'components/Row'
import TokenImage from 'components/TokenImage'
import TooltipInfo from 'components/TooltipInfo'
import { FarmLPPool } from 'gql/microservices/farm-lp-pools'
import { useActiveWeb3React } from 'hooks'
import { useGetBoostNettFarmProxy, useGetFarm } from 'hooks/contract/useGetContract'
import Numeral from 'numeral'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { TYPE } from 'theme'

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  background: ${({ theme }) => theme.v2.bg02};
  border-radius: 12px;
  cursor: pointer;
`

const PairImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  div {
    &:first-child {
      z-index: 1;
    }
    &:last-child {
      z-index: 0;
      margin-left: -10px;
    }
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
`

const TagWrapper = styled(Row)`
  > div {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`

const PoolTag = styled.div`
  padding: 4px 12px;
  background: ${({ theme }) => theme.v2.bg01};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.v2.t01};
  font-size: 10px;
`

const InfoColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const StyledButton = styled(AuthButton)`
  /* width: 120px; */
  height: 32px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.v2.t09};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  /* color: ${({ theme }) => theme.v2.t09}; */
`

interface Props {
  pool: FarmLPPool
  showDetailApr?: boolean
}

export default function PoolItem({ pool, showDetailApr }: Props) {
  const history = useHistory()
  const { account } = useActiveWeb3React()
  const [userStakeAmount, setUserStakeAmount] = useState(new BigNumber(0))
  const farmContract = useGetFarm()
  const boostFarmContract = useGetBoostNettFarmProxy()

  const stakeContract = pool?.isBoosted ? boostFarmContract : farmContract
  async function getStakeBalance() {
    if (stakeContract && account) {
      try {
        const res = await stakeContract.userInfo(pool.id, account)
        setUserStakeAmount(
          res?.amount.toString() ? new BigNumber(res.amount.toString()).shiftedBy(-18) : new BigNumber(0)
        )
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    getStakeBalance()
  }, [stakeContract, account])

  const lpName = useMemo(() => {
    return `${pool.pair.token0.symbol} - ${pool.pair.token1.symbol}`
  }, [pool.pair])

  const totalApr = useMemo(() => {
    return pool.rewarderApy + pool.lpApy + pool.nettApy
  }, [pool])

  const lpPrice = useMemo(() => {
    return new BigNumber(pool.pair.reserveUSD).dividedBy(pool.pair.totalSupply).toNumber()
  }, [pool])

  const userStakedUSD = useMemo(() => {
    return userStakeAmount.multipliedBy(lpPrice).toNumber()
  }, [userStakeAmount, lpPrice])

  const hideNett = useMemo(() => {
    return Number(pool.allocPoint) === 0
  }, [pool.allocPoint])

  const normalFlag = useMemo(() => {
    if (!hideNett && pool.rewarder) {
      return `Nett+${pool.rewarder.rewardToken.symbol}`
    }

    if (hideNett) {
      if (pool.rewarder) {
        return `${pool.rewarder.rewardToken.symbol}`
      }
    }

    return 'Nett'
  }, [hideNett, pool.rewarder])

  return (
    <Wrapper
      id={`${pool?.isBoosted ? 'boost' : 'normal'}-${pool.id}`}
      onClick={() => {
        history.push(`/f/${pool?.isBoosted ? 'boost' : 'normal'}/${pool.id}`)
      }}
    >
      <RowBetween>
        <Row>
          <PairImgWrapper>
            <TokenImage size="24px" address={pool.pair.token0.address} />
            <TokenImage size="24px" address={pool.pair.token1.address} />
          </PairImgWrapper>
          <TYPE.v2Main style={{ flexShrink: 0 }} fontSize={16}>
            {lpName}
          </TYPE.v2Main>
        </Row>
        <TagWrapper justify="flex-end">
          {pool.isBoosted && <PoolTag>Boost</PoolTag>}
          {pool.rewarder && <PoolTag>{normalFlag}</PoolTag>}
        </TagWrapper>
      </RowBetween>
      <RowBetween marginTop="24px">
        <InfoColumn>
          <TYPE.desc fontSize={10} style={{ display: 'flex', alignItems: 'center' }}>
            TVL{' '}
            <TooltipInfo
              style={{ display: 'flex', alignItems: 'center' }}
              text="Total value of the funds in this farm’s liquidity pool"
            />
          </TYPE.desc>
          <TYPE.v2Main fontSize={16} marginTop="6px" fontWeight={500}>
            {Numeral(pool.totalDepositVolumeUSD).format('$0,0')}
          </TYPE.v2Main>
        </InfoColumn>
        {showDetailApr ? (
          <>
            <InfoColumn>
              <TYPE.desc fontSize={10} style={{ display: 'flex', alignItems: 'center' }}>
                LP APR{' '}
                <TooltipInfo
                  style={{ display: 'flex', alignItems: 'center' }}
                  text="This is the APR of earning 0.25% fees for LP. Based on last 24hrs performance"
                />
              </TYPE.desc>

              <TYPE.v2Main fontSize={16} marginTop="6px" fontWeight={500}>
                {pool.lpApy} %
              </TYPE.v2Main>
            </InfoColumn>
            <InfoColumn>
              <TYPE.desc fontSize={10}>NETT APR</TYPE.desc>
              <TYPE.v2Main fontSize={16} marginTop="10px" fontWeight={500}>
                {pool.nettApy} %
              </TYPE.v2Main>
            </InfoColumn>
          </>
        ) : (
          <InfoColumn>
            <TYPE.desc fontSize={10} style={{ display: 'flex', alignItems: 'center' }}>
              Total APR
            </TYPE.desc>

            <TYPE.v2Main fontSize={16} marginTop="10px" fontWeight={500}>
              {totalApr.toFixed(3)} %
            </TYPE.v2Main>
          </InfoColumn>
        )}

        <InfoColumn>
          <TYPE.desc fontSize={10}>Your Stake</TYPE.desc>
          <TYPE.v2Main fontSize={16} marginTop="10px" fontWeight={500}>
            {account ? Numeral(userStakedUSD).format('$0,0.[00]') : <StyledButton auth>Connect Wallet</StyledButton>}
          </TYPE.v2Main>
        </InfoColumn>
      </RowBetween>
    </Wrapper>
  )
}
